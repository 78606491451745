import React from "react";
import HelpPage from "../../components/helpPage";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import { graphql } from "gatsby";
import Img from "gatsby-image";

export const query = graphql`
  query {
    zdjecie1: file(
      relativePath: { eq: "Program-CRM-masowe-operacje-na-kontrahentach.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(relativePath: { eq: "Program-CRM-masowe-operacje.png" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie3: file(
      relativePath: {
        eq: "Program-CRM-masowe-operacje-na-kontrahentach-dodawanie-zadania.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie4: file(
      relativePath: { eq: "Program-CRM-masowe-dodawanie-zadan.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const OperacjeMasowe = ({ data }) => {
  return (
    <HelpPage
      name="Operacje masowe"
      metaTitle="Pomoc - operacje masowe w prostym systemie CRM - QuickCRM"
      metaDescription="Operacje masowe - pomoc w systemie CRM • Jak wykonywać operacje masowe? • Wypróbuj QuickCRM i sprzedawaj więcej, pierwsze 30 dni bezpłatnie!"
    >
      <div>
        <p>
          Operacje masowe w systemie CRM to sposób na znaczne usprawnienie
          codziennie wykonywanych obowiązków. Jak to działa? Z system QuickCRM
          masz możliwość dodawania zadań i zdarzeń jednocześnie dla wielu
          kontrahentów lub osób kontaktowych. Przekonaj się, jakie to łatwe.
        </p>
      </div>
      <Tabs defaultTab="zakladka1">
        <TabList>
          <Tab tabFor="zakladka1">[1] Operacje masowe</Tab>
        </TabList>
        <TabPanel tabId="zakladka1">
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Operacje masowe na kontrahentach"
            fluid={data.zdjecie1.childImageSharp.fluid}
          />

          <p>
            [1] Aby wykonać masowe operacje dotyczące kontrahentów, przejdź do
            listy kontrahentów i kliknij przycisk “dodaj do schowska wszystkie
            znalezione” lub zaznacz wybranych kontrahentów i kliknij przycisk
            “dodaj wybrane firmy do schowka”.
          </p>

          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Operacje masowe"
            fluid={data.zdjecie2.childImageSharp.fluid}
          />
          <p>
            [2] Po wskazaniu kontrahentów, zostaniesz automatycznie przeniesiony
            do schowka. W tym miejscu możesz wykonywać masowe akcje:
          </p>
          <p>
            <li>
              edytować, np. nadać wszystkim firmom w schowku opiekuna
              handlowego,
            </li>
            <li>
              dodawać zadania, np. zaplanować kontakt w sprawie nowej promocji,
            </li>
            <li>dodawać zdarzenia, np. wykonany kontakt,</li>
            <li>usuwać.</li>
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Operacje masowe dodawanie zadania"
            fluid={data.zdjecie3.childImageSharp.fluid}
          />
          <p>
            [3] Tworzenie zadania dla wszystkich kontrahentów w schowku. Każdy z
            nich będzie miał przypisane osobne zadanie, które możesz zobaczyć w
            karcie kontrahenta oraz w kalendarzu pracownika.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Masowe dodawanie zadań"
            fluid={data.zdjecie4.childImageSharp.fluid}
          />
          <p>
            [4] Jeśli chcesz zobaczyć, czy Twoje zadanie zostało utworzone,
            wybierz z menu “Kontrahenci”, następnie wybierz kontrahenta, do
            którego przypisałeś zadanie i przejdź do zakładki “Zadania i
            zdarzenia”.
          </p>
        </TabPanel>
      </Tabs>
    </HelpPage>
  );
};

export default OperacjeMasowe;
